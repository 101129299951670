@import url(https://cdn.jsdelivr.net/gh/alyssaxuu/flowy/flowy.min.css);
/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #1b4044;
  --ion-color-primary-rgb: 27, 64, 68;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #18383c;
  --ion-color-primary-tint: #325357;
  --ion-color-secondary: #4cb8c4;
  --ion-color-secondary-rgb: 76, 184, 196;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #43a2ac;
  --ion-color-secondary-tint: #5ebfca;
  --ion-color-tertiary: #3dc2ff;
  --ion-color-tertiary-rgb: 61, 194, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #36abe0;
  --ion-color-tertiary-tint: #50c8ff;
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  --ion-color-medium: #efeff4;
  --ion-color-medium-rgb: 239, 239, 244;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #d2d2d7;
  --ion-color-medium-tint: #f1f1f5;
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  --ion-sop-title-color: #000000;
}
:root {
  --ion-font-family: "Open Sans", Helvetica, sans-serif;
  --ion-background-color: #efeff4;
  --ion-toolbar-background: var(--ion-color-primary);
  --ion-toolbar-color: var(--ion-color-primary-contrast);
  --ion-toolbar-height: 56px;
  --ion-color-border: #878787;
  --ion-color-border-rgb: 135, 135, 135;
  --ion-color-border-contrast: #000000;
  --ion-color-border-contrast-rgb: 0, 0, 0;
  --ion-color-border-shade: #777777;
  --ion-color-border-tint: #939393;
  --ion-color-disabled: #878787;
  --ion-color-disabled-rgb: 135, 135, 135;
  --ion-color-disabled-contrast: #000000;
  --ion-color-disabled-contrast-rgb: 0, 0, 0;
  --ion-color-disabled-shade: #777777;
  --ion-color-disabled-tint: #939393;
  --ion-color-container-background: #ffffff;
  --ion-color-container-background-rgb: 255, 255, 255;
  --ion-color-container-background-contrast: #000000;
  --ion-color-container-background-contrast-rgb: 0, 0, 0;
  --ion-color-container-background-shade: #e0e0e0;
  --ion-color-container-background-tint: #ffffff;
}
/*
.ion-color-border {
  --ion-color-base: var(--ion-color-border);
  --ion-color-base-rgb: var(--ion-color-border-rgb);
  --ion-color-contrast: var(--ion-color-border-contrast);
  --ion-color-contrast-rgb: var(--ion-color-border-contrast-rgb);
  --ion-color-shade: var(--ion-color-border-shade);
  --ion-color-tint: var(--ion-color-border-tint);
}

.ion-color-container-background {
  --ion-color-base: var(--ion-color-container-background);
  --ion-color-base-rgb: var(--ion-color-container-background-rgb);
  --ion-color-contrast: var(--ion-color-container-background-contrast);
  --ion-color-contrast-rgb: var(--ion-color-container-background-contrast-rgb);
  --ion-color-shade: var(--ion-color-container-background-shade);
  --ion-color-tint: var(--ion-color-container-background-tint);
}
*/

/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
html.ios {
  --ion-default-font: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif;
}
html.md {
  --ion-default-font: "Roboto", "Helvetica Neue", sans-serif;
}
html {
  --ion-font-family: var(--ion-default-font);
}
body {
  background: var(--ion-background-color);
}
body.backdrop-no-scroll {
  overflow: hidden;
}
html.ios ion-modal.modal-card .ion-page > ion-header > ion-toolbar:first-of-type {
  padding-top: 0px;
}
html.ios ion-modal .ion-page {
  border-radius: inherit;
}
.ion-color-primary {
  --ion-color-base: var(--ion-color-primary, #3880ff) !important;
  --ion-color-base-rgb: var(--ion-color-primary-rgb, 56, 128, 255) !important;
  --ion-color-contrast: var(--ion-color-primary-contrast, #fff) !important;
  --ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb, 255, 255, 255) !important;
  --ion-color-shade: var(--ion-color-primary-shade, #3171e0) !important;
  --ion-color-tint: var(--ion-color-primary-tint, #4c8dff) !important;
}
.ion-color-secondary {
  --ion-color-base: var(--ion-color-secondary, #3dc2ff) !important;
  --ion-color-base-rgb: var(--ion-color-secondary-rgb, 61, 194, 255) !important;
  --ion-color-contrast: var(--ion-color-secondary-contrast, #fff) !important;
  --ion-color-contrast-rgb: var(--ion-color-secondary-contrast-rgb, 255, 255, 255) !important;
  --ion-color-shade: var(--ion-color-secondary-shade, #36abe0) !important;
  --ion-color-tint: var(--ion-color-secondary-tint, #50c8ff) !important;
}
.ion-color-tertiary {
  --ion-color-base: var(--ion-color-tertiary, #5260ff) !important;
  --ion-color-base-rgb: var(--ion-color-tertiary-rgb, 82, 96, 255) !important;
  --ion-color-contrast: var(--ion-color-tertiary-contrast, #fff) !important;
  --ion-color-contrast-rgb: var(--ion-color-tertiary-contrast-rgb, 255, 255, 255) !important;
  --ion-color-shade: var(--ion-color-tertiary-shade, #4854e0) !important;
  --ion-color-tint: var(--ion-color-tertiary-tint, #6370ff) !important;
}
.ion-color-success {
  --ion-color-base: var(--ion-color-success, #2dd36f) !important;
  --ion-color-base-rgb: var(--ion-color-success-rgb, 45, 211, 111) !important;
  --ion-color-contrast: var(--ion-color-success-contrast, #fff) !important;
  --ion-color-contrast-rgb: var(--ion-color-success-contrast-rgb, 255, 255, 255) !important;
  --ion-color-shade: var(--ion-color-success-shade, #28ba62) !important;
  --ion-color-tint: var(--ion-color-success-tint, #42d77d) !important;
}
.ion-color-warning {
  --ion-color-base: var(--ion-color-warning, #ffc409) !important;
  --ion-color-base-rgb: var(--ion-color-warning-rgb, 255, 196, 9) !important;
  --ion-color-contrast: var(--ion-color-warning-contrast, #000) !important;
  --ion-color-contrast-rgb: var(--ion-color-warning-contrast-rgb, 0, 0, 0) !important;
  --ion-color-shade: var(--ion-color-warning-shade, #e0ac08) !important;
  --ion-color-tint: var(--ion-color-warning-tint, #ffca22) !important;
}
.ion-color-danger {
  --ion-color-base: var(--ion-color-danger, #eb445a) !important;
  --ion-color-base-rgb: var(--ion-color-danger-rgb, 235, 68, 90) !important;
  --ion-color-contrast: var(--ion-color-danger-contrast, #fff) !important;
  --ion-color-contrast-rgb: var(--ion-color-danger-contrast-rgb, 255, 255, 255) !important;
  --ion-color-shade: var(--ion-color-danger-shade, #cf3c4f) !important;
  --ion-color-tint: var(--ion-color-danger-tint, #ed576b) !important;
}
.ion-color-light {
  --ion-color-base: var(--ion-color-light, #f4f5f8) !important;
  --ion-color-base-rgb: var(--ion-color-light-rgb, 244, 245, 248) !important;
  --ion-color-contrast: var(--ion-color-light-contrast, #000) !important;
  --ion-color-contrast-rgb: var(--ion-color-light-contrast-rgb, 0, 0, 0) !important;
  --ion-color-shade: var(--ion-color-light-shade, #d7d8da) !important;
  --ion-color-tint: var(--ion-color-light-tint, #f5f6f9) !important;
}
.ion-color-medium {
  --ion-color-base: var(--ion-color-medium, #92949c) !important;
  --ion-color-base-rgb: var(--ion-color-medium-rgb, 146, 148, 156) !important;
  --ion-color-contrast: var(--ion-color-medium-contrast, #fff) !important;
  --ion-color-contrast-rgb: var(--ion-color-medium-contrast-rgb, 255, 255, 255) !important;
  --ion-color-shade: var(--ion-color-medium-shade, #808289) !important;
  --ion-color-tint: var(--ion-color-medium-tint, #9d9fa6) !important;
}
.ion-color-dark {
  --ion-color-base: var(--ion-color-dark, #222428) !important;
  --ion-color-base-rgb: var(--ion-color-dark-rgb, 34, 36, 40) !important;
  --ion-color-contrast: var(--ion-color-dark-contrast, #fff) !important;
  --ion-color-contrast-rgb: var(--ion-color-dark-contrast-rgb, 255, 255, 255) !important;
  --ion-color-shade: var(--ion-color-dark-shade, #1e2023) !important;
  --ion-color-tint: var(--ion-color-dark-tint, #383a3e) !important;
}
.ion-page {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  contain: layout size style;
  overflow: hidden;
  z-index: 0;
}
.split-pane-visible > .ion-page.split-pane-main {
  position: relative;
}
ion-route,
ion-route-redirect,
ion-router,
ion-select-option,
ion-nav-controller,
ion-menu-controller,
ion-action-sheet-controller,
ion-alert-controller,
ion-loading-controller,
ion-modal-controller,
ion-picker-controller,
ion-popover-controller,
ion-toast-controller,
.ion-page-hidden,
[hidden] {
  /* stylelint-disable-next-line declaration-no-important */
  display: none !important;
}
.ion-page-invisible {
  opacity: 0;
}
.can-go-back > ion-header ion-back-button {
  display: block;
}
html.plt-ios.plt-hybrid, html.plt-ios.plt-pwa {
  --ion-statusbar-padding: 20px;
}
@supports (padding-top: 20px) {
  html {
    --ion-safe-area-top: var(--ion-statusbar-padding);
  }
}
@supports (padding-top: constant(safe-area-inset-top)) {
  html {
    --ion-safe-area-top: constant(safe-area-inset-top);
    --ion-safe-area-bottom: constant(safe-area-inset-bottom);
    --ion-safe-area-left: constant(safe-area-inset-left);
    --ion-safe-area-right: constant(safe-area-inset-right);
  }
}
@supports (padding-top: env(safe-area-inset-top)) {
  html {
    --ion-safe-area-top: env(safe-area-inset-top);
    --ion-safe-area-bottom: env(safe-area-inset-bottom);
    --ion-safe-area-left: env(safe-area-inset-left);
    --ion-safe-area-right: env(safe-area-inset-right);
  }
}
ion-card.ion-color .ion-inherit-color,
ion-card-header.ion-color .ion-inherit-color {
  color: inherit;
}
.menu-content {
  transform: translate3d(0,  0,  0);
}
.menu-content-open {
  cursor: pointer;
  touch-action: manipulation;
  pointer-events: none;
}
.ios .menu-content-reveal {
  box-shadow: -8px 0 42px rgba(0, 0, 0, 0.08);
}
[dir=rtl].ios .menu-content-reveal {
  box-shadow: 8px 0 42px rgba(0, 0, 0, 0.08);
}
.md .menu-content-reveal {
  box-shadow: 4px 0px 16px rgba(0, 0, 0, 0.18);
}
.md .menu-content-push {
  box-shadow: 4px 0px 16px rgba(0, 0, 0, 0.18);
}
/* Basic CSS for apps built with Ionic */
audio,
canvas,
progress,
video {
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
b,
strong {
  font-weight: bold;
}
img {
  max-width: 100%;
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
figure {
  margin: 1em 40px;
}
hr {
  height: 1px;
  border-width: 0;
  box-sizing: content-box;
}
pre {
  overflow: auto;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
label,
input,
select,
textarea {
  font-family: inherit;
  line-height: normal;
}
textarea {
  overflow: auto;
  height: auto;
  font: inherit;
  color: inherit;
}
textarea::placeholder {
  padding-left: 2px;
}
form,
input,
optgroup,
select {
  margin: 0;
  font: inherit;
  color: inherit;
}
html input[type=button],
input[type=reset],
input[type=submit] {
  cursor: pointer;
  -webkit-appearance: button;
}
a,
a div,
a span,
a ion-icon,
a ion-label,
button,
button div,
button span,
button ion-icon,
button ion-label,
.ion-tappable,
[tappable],
[tappable] div,
[tappable] span,
[tappable] ion-icon,
[tappable] ion-label,
input,
textarea {
  touch-action: manipulation;
}
a ion-label,
button ion-label {
  pointer-events: none;
}
button {
  border: 0;
  border-radius: 0;
  font-family: inherit;
  font-style: inherit;
  font-variant: inherit;
  line-height: 1;
  text-transform: none;
  cursor: pointer;
  -webkit-appearance: button;
}
[tappable] {
  cursor: pointer;
}
a[disabled],
button[disabled],
html input[disabled] {
  cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}
input[type=checkbox],
input[type=radio] {
  padding: 0;
  box-sizing: border-box;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  height: auto;
}
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
td,
th {
  padding: 0;
}
* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
html {
  width: 100%;
  height: 100%;
  -webkit-text-size-adjust: 100%;
          text-size-adjust: 100%;
}
html:not(.hydrated) body {
  display: none;
}
html.plt-pwa {
  height: 100vh;
}
body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  position: fixed;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  text-rendering: optimizeLegibility;
  overflow: hidden;
  touch-action: manipulation;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  word-wrap: break-word;
  overscroll-behavior-y: none;
  -webkit-text-size-adjust: none;
          text-size-adjust: none;
}
html {
  font-family: var(--ion-font-family);
}
a {
  background-color: transparent;
  color: var(--ion-color-primary, #3880ff);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 16px;
  margin-bottom: 10px;
  font-weight: 500;
  line-height: 1.2;
}
h1 {
  margin-top: 20px;
  font-size: 26px;
}
h2 {
  margin-top: 18px;
  font-size: 24px;
}
h3 {
  font-size: 22px;
}
h4 {
  font-size: 20px;
}
h5 {
  font-size: 18px;
}
h6 {
  font-size: 16px;
}
small {
  font-size: 75%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
.ion-hide {
  display: none !important;
}
.ion-hide-up {
  display: none !important;
}
.ion-hide-down {
  display: none !important;
}
@media (min-width: 576px) {
  .ion-hide-sm-up {
    display: none !important;
  }
}
@media (max-width: 575.98px) {
  .ion-hide-sm-down {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .ion-hide-md-up {
    display: none !important;
  }
}
@media (max-width: 767.98px) {
  .ion-hide-md-down {
    display: none !important;
  }
}
@media (min-width: 992px) {
  .ion-hide-lg-up {
    display: none !important;
  }
}
@media (max-width: 991.98px) {
  .ion-hide-lg-down {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .ion-hide-xl-up {
    display: none !important;
  }
}
@media (max-width: 1199.98px) {
  .ion-hide-xl-down {
    display: none !important;
  }
}
/* Optional CSS utils that can be commented out */
.ion-no-padding {
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.ion-padding {
  --padding-start: var(--ion-padding, 16px);
  --padding-end: var(--ion-padding, 16px);
  --padding-top: var(--ion-padding, 16px);
  --padding-bottom: var(--ion-padding, 16px);
  padding-left: var(--ion-padding, 16px);
  padding-right: var(--ion-padding, 16px);
  padding-top: var(--ion-padding, 16px);
  padding-bottom: var(--ion-padding, 16px);
}
@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
  .ion-padding {
    padding-left: unset;
    padding-right: unset;
    padding-inline-start: var(--ion-padding, 16px);
    padding-inline-end: var(--ion-padding, 16px);
  }
}
.ion-padding-top {
  --padding-top: var(--ion-padding, 16px);
  padding-top: var(--ion-padding, 16px);
}
.ion-padding-start {
  --padding-start: var(--ion-padding, 16px);
  padding-left: var(--ion-padding, 16px);
}
@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
  .ion-padding-start {
    padding-left: unset;
    padding-inline-start: var(--ion-padding, 16px);
  }
}
.ion-padding-end {
  --padding-end: var(--ion-padding, 16px);
  padding-right: var(--ion-padding, 16px);
}
@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
  .ion-padding-end {
    padding-right: unset;
    padding-inline-end: var(--ion-padding, 16px);
  }
}
.ion-padding-bottom {
  --padding-bottom: var(--ion-padding, 16px);
  padding-bottom: var(--ion-padding, 16px);
}
.ion-padding-vertical {
  --padding-top: var(--ion-padding, 16px);
  --padding-bottom: var(--ion-padding, 16px);
  padding-top: var(--ion-padding, 16px);
  padding-bottom: var(--ion-padding, 16px);
}
.ion-padding-horizontal {
  --padding-start: var(--ion-padding, 16px);
  --padding-end: var(--ion-padding, 16px);
  padding-left: var(--ion-padding, 16px);
  padding-right: var(--ion-padding, 16px);
}
@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
  .ion-padding-horizontal {
    padding-left: unset;
    padding-right: unset;
    padding-inline-start: var(--ion-padding, 16px);
    padding-inline-end: var(--ion-padding, 16px);
  }
}
.ion-no-margin {
  --margin-start: 0;
  --margin-end: 0;
  --margin-top: 0;
  --margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.ion-margin {
  --margin-start: var(--ion-margin, 16px);
  --margin-end: var(--ion-margin, 16px);
  --margin-top: var(--ion-margin, 16px);
  --margin-bottom: var(--ion-margin, 16px);
  margin-left: var(--ion-margin, 16px);
  margin-right: var(--ion-margin, 16px);
  margin-top: var(--ion-margin, 16px);
  margin-bottom: var(--ion-margin, 16px);
}
@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
  .ion-margin {
    margin-left: unset;
    margin-right: unset;
    margin-inline-start: var(--ion-margin, 16px);
    margin-inline-end: var(--ion-margin, 16px);
  }
}
.ion-margin-top {
  --margin-top: var(--ion-margin, 16px);
  margin-top: var(--ion-margin, 16px);
}
.ion-margin-start {
  --margin-start: var(--ion-margin, 16px);
  margin-left: var(--ion-margin, 16px);
}
@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
  .ion-margin-start {
    margin-left: unset;
    margin-inline-start: var(--ion-margin, 16px);
  }
}
.ion-margin-end {
  --margin-end: var(--ion-margin, 16px);
  margin-right: var(--ion-margin, 16px);
}
@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
  .ion-margin-end {
    margin-right: unset;
    margin-inline-end: var(--ion-margin, 16px);
  }
}
.ion-margin-bottom {
  --margin-bottom: var(--ion-margin, 16px);
  margin-bottom: var(--ion-margin, 16px);
}
.ion-margin-vertical {
  --margin-top: var(--ion-margin, 16px);
  --margin-bottom: var(--ion-margin, 16px);
  margin-top: var(--ion-margin, 16px);
  margin-bottom: var(--ion-margin, 16px);
}
.ion-margin-horizontal {
  --margin-start: var(--ion-margin, 16px);
  --margin-end: var(--ion-margin, 16px);
  margin-left: var(--ion-margin, 16px);
  margin-right: var(--ion-margin, 16px);
}
@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
  .ion-margin-horizontal {
    margin-left: unset;
    margin-right: unset;
    margin-inline-start: var(--ion-margin, 16px);
    margin-inline-end: var(--ion-margin, 16px);
  }
}
.ion-float-left {
  float: left !important;
}
.ion-float-right {
  float: right !important;
}
.ion-float-start {
  float: left !important;
}
[dir=rtl] .ion-float-start, :host-context([dir=rtl]) .ion-float-start {
  float: right !important;
}
.ion-float-end {
  float: right !important;
}
[dir=rtl] .ion-float-end, :host-context([dir=rtl]) .ion-float-end {
  float: left !important;
}
@media (min-width: 576px) {
  .ion-float-sm-left {
    float: left !important;
  }

  .ion-float-sm-right {
    float: right !important;
  }

  .ion-float-sm-start {
    float: left !important;
  }
  [dir=rtl] .ion-float-sm-start, :host-context([dir=rtl]) .ion-float-sm-start {
    float: right !important;
  }

  .ion-float-sm-end {
    float: right !important;
  }
  [dir=rtl] .ion-float-sm-end, :host-context([dir=rtl]) .ion-float-sm-end {
    float: left !important;
  }
}
@media (min-width: 768px) {
  .ion-float-md-left {
    float: left !important;
  }

  .ion-float-md-right {
    float: right !important;
  }

  .ion-float-md-start {
    float: left !important;
  }
  [dir=rtl] .ion-float-md-start, :host-context([dir=rtl]) .ion-float-md-start {
    float: right !important;
  }

  .ion-float-md-end {
    float: right !important;
  }
  [dir=rtl] .ion-float-md-end, :host-context([dir=rtl]) .ion-float-md-end {
    float: left !important;
  }
}
@media (min-width: 992px) {
  .ion-float-lg-left {
    float: left !important;
  }

  .ion-float-lg-right {
    float: right !important;
  }

  .ion-float-lg-start {
    float: left !important;
  }
  [dir=rtl] .ion-float-lg-start, :host-context([dir=rtl]) .ion-float-lg-start {
    float: right !important;
  }

  .ion-float-lg-end {
    float: right !important;
  }
  [dir=rtl] .ion-float-lg-end, :host-context([dir=rtl]) .ion-float-lg-end {
    float: left !important;
  }
}
@media (min-width: 1200px) {
  .ion-float-xl-left {
    float: left !important;
  }

  .ion-float-xl-right {
    float: right !important;
  }

  .ion-float-xl-start {
    float: left !important;
  }
  [dir=rtl] .ion-float-xl-start, :host-context([dir=rtl]) .ion-float-xl-start {
    float: right !important;
  }

  .ion-float-xl-end {
    float: right !important;
  }
  [dir=rtl] .ion-float-xl-end, :host-context([dir=rtl]) .ion-float-xl-end {
    float: left !important;
  }
}
.ion-text-center {
  text-align: center !important;
}
.ion-text-justify {
  text-align: justify !important;
}
.ion-text-start {
  text-align: start !important;
}
.ion-text-end {
  text-align: end !important;
}
.ion-text-left {
  text-align: left !important;
}
.ion-text-right {
  text-align: right !important;
}
.ion-text-nowrap {
  white-space: nowrap !important;
}
.ion-text-wrap {
  white-space: normal !important;
}
@media (min-width: 576px) {
  .ion-text-sm-center {
    text-align: center !important;
  }

  .ion-text-sm-justify {
    text-align: justify !important;
  }

  .ion-text-sm-start {
    text-align: start !important;
  }

  .ion-text-sm-end {
    text-align: end !important;
  }

  .ion-text-sm-left {
    text-align: left !important;
  }

  .ion-text-sm-right {
    text-align: right !important;
  }

  .ion-text-sm-nowrap {
    white-space: nowrap !important;
  }

  .ion-text-sm-wrap {
    white-space: normal !important;
  }
}
@media (min-width: 768px) {
  .ion-text-md-center {
    text-align: center !important;
  }

  .ion-text-md-justify {
    text-align: justify !important;
  }

  .ion-text-md-start {
    text-align: start !important;
  }

  .ion-text-md-end {
    text-align: end !important;
  }

  .ion-text-md-left {
    text-align: left !important;
  }

  .ion-text-md-right {
    text-align: right !important;
  }

  .ion-text-md-nowrap {
    white-space: nowrap !important;
  }

  .ion-text-md-wrap {
    white-space: normal !important;
  }
}
@media (min-width: 992px) {
  .ion-text-lg-center {
    text-align: center !important;
  }

  .ion-text-lg-justify {
    text-align: justify !important;
  }

  .ion-text-lg-start {
    text-align: start !important;
  }

  .ion-text-lg-end {
    text-align: end !important;
  }

  .ion-text-lg-left {
    text-align: left !important;
  }

  .ion-text-lg-right {
    text-align: right !important;
  }

  .ion-text-lg-nowrap {
    white-space: nowrap !important;
  }

  .ion-text-lg-wrap {
    white-space: normal !important;
  }
}
@media (min-width: 1200px) {
  .ion-text-xl-center {
    text-align: center !important;
  }

  .ion-text-xl-justify {
    text-align: justify !important;
  }

  .ion-text-xl-start {
    text-align: start !important;
  }

  .ion-text-xl-end {
    text-align: end !important;
  }

  .ion-text-xl-left {
    text-align: left !important;
  }

  .ion-text-xl-right {
    text-align: right !important;
  }

  .ion-text-xl-nowrap {
    white-space: nowrap !important;
  }

  .ion-text-xl-wrap {
    white-space: normal !important;
  }
}
.ion-text-uppercase {
  /* stylelint-disable-next-line declaration-no-important */
  text-transform: uppercase !important;
}
.ion-text-lowercase {
  /* stylelint-disable-next-line declaration-no-important */
  text-transform: lowercase !important;
}
.ion-text-capitalize {
  /* stylelint-disable-next-line declaration-no-important */
  text-transform: capitalize !important;
}
@media (min-width: 576px) {
  .ion-text-sm-uppercase {
    /* stylelint-disable-next-line declaration-no-important */
    text-transform: uppercase !important;
  }

  .ion-text-sm-lowercase {
    /* stylelint-disable-next-line declaration-no-important */
    text-transform: lowercase !important;
  }

  .ion-text-sm-capitalize {
    /* stylelint-disable-next-line declaration-no-important */
    text-transform: capitalize !important;
  }
}
@media (min-width: 768px) {
  .ion-text-md-uppercase {
    /* stylelint-disable-next-line declaration-no-important */
    text-transform: uppercase !important;
  }

  .ion-text-md-lowercase {
    /* stylelint-disable-next-line declaration-no-important */
    text-transform: lowercase !important;
  }

  .ion-text-md-capitalize {
    /* stylelint-disable-next-line declaration-no-important */
    text-transform: capitalize !important;
  }
}
@media (min-width: 992px) {
  .ion-text-lg-uppercase {
    /* stylelint-disable-next-line declaration-no-important */
    text-transform: uppercase !important;
  }

  .ion-text-lg-lowercase {
    /* stylelint-disable-next-line declaration-no-important */
    text-transform: lowercase !important;
  }

  .ion-text-lg-capitalize {
    /* stylelint-disable-next-line declaration-no-important */
    text-transform: capitalize !important;
  }
}
@media (min-width: 1200px) {
  .ion-text-xl-uppercase {
    /* stylelint-disable-next-line declaration-no-important */
    text-transform: uppercase !important;
  }

  .ion-text-xl-lowercase {
    /* stylelint-disable-next-line declaration-no-important */
    text-transform: lowercase !important;
  }

  .ion-text-xl-capitalize {
    /* stylelint-disable-next-line declaration-no-important */
    text-transform: capitalize !important;
  }
}
.ion-align-self-start {
  align-self: flex-start !important;
}
.ion-align-self-end {
  align-self: flex-end !important;
}
.ion-align-self-center {
  align-self: center !important;
}
.ion-align-self-stretch {
  align-self: stretch !important;
}
.ion-align-self-baseline {
  align-self: baseline !important;
}
.ion-align-self-auto {
  align-self: auto !important;
}
.ion-wrap {
  flex-wrap: wrap !important;
}
.ion-nowrap {
  flex-wrap: nowrap !important;
}
.ion-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.ion-justify-content-start {
  justify-content: flex-start !important;
}
.ion-justify-content-center {
  justify-content: center !important;
}
.ion-justify-content-end {
  justify-content: flex-end !important;
}
.ion-justify-content-around {
  justify-content: space-around !important;
}
.ion-justify-content-between {
  justify-content: space-between !important;
}
.ion-justify-content-evenly {
  justify-content: space-evenly !important;
}
.ion-align-items-start {
  align-items: flex-start !important;
}
.ion-align-items-center {
  align-items: center !important;
}
.ion-align-items-end {
  align-items: flex-end !important;
}
.ion-align-items-stretch {
  align-items: stretch !important;
}
.ion-align-items-baseline {
  align-items: baseline !important;
}
ion-modal {
  --background: $background;
  --border-color: var(--ion-color-border);
}
ion-modal .modal-wrapper {
  background-color: var(--ion-color-container-background) !important;
  color: var(--ion-color-container-background-contrast) !important;
  border-radius: 12px !important;
}
ion-modal .modal-wrapper ion-content {
  --background: $background;
  --color: $color;
  padding: 16px;
}
ion-backdrop {
  --backdrop-opacity: 0.66;
}
.split-pane-visible > .split-pane-side {
  min-width: 150px;
  /* Default: 270px */
  max-width: 200px;
  /* Same as when menu opens in mobile mode */
}
ion-toolbar {
  height: var(--ion-toolbar-height);
}
.row-striped {
  --background: $odd-background-color;
  background: var(--ion-color-container-background);
  --color: $odd-color;
  color: var(--ion-color-container-background-contrast);
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.row-striped ion-select::part(text),
.row-striped ion-select::part(icon) {
  color: var(--ion-color-container-background-contrast);
  opacity: 1;
}
.row-striped ion-select::part(placeholder) {
  color: var(--ion-color-container-background-contrast);
  opacity: 0.8;
}
.row-striped ion-select::part(text),
.row-striped ion-select::part(placeholder) {
  margin-right: 18px;
}
.row-striped ion-item {
  --background: transparent;
  background: transparent;
}
.row-striped ion-select {
  --background: transparent;
  background: transparent;
}
.row-striped ion-item {
  color: var(--ion-color-container-background-contrast);
}
.row-striped ion-label {
  color: var(--ion-color-container-background-contrast);
}
.row-striped ion-text {
  color: var(--ion-color-container-background-contrast);
}
.row-striped ion-icon {
  color: var(--ion-color-container-background-contrast);
}
.row-striped ion-radio {
  --color: $odd-color;
  --color-checked: $odd-color;
}
.row-striped:nth-child(2n+1) {
  --background: $even-background-color;
  background: var(--ion-color-secondary);
  --color: $even-color;
  color: var(--ion-color-secondary-contrast);
}
.row-striped:nth-child(2n+1) ion-select::part(text),
.row-striped:nth-child(2n+1) ion-select::part(icon) {
  color: var(--ion-color-secondary-contrast);
}
.row-striped:nth-child(2n+1) ion-select::part(placeholder) {
  color: var(--ion-color-secondary-contrast);
}
.row-striped:nth-child(2n+1) ion-item {
  --background: transparent;
  background: transparent;
}
.row-striped:nth-child(2n+1) ion-select {
  --background: transparent;
  background: transparent;
}
.row-striped:nth-child(2n+1) ion-item {
  color: var(--ion-color-secondary-contrast);
}
.row-striped:nth-child(2n+1) ion-label {
  color: var(--ion-color-secondary-contrast);
}
.row-striped:nth-child(2n+1) ion-text {
  color: var(--ion-color-secondary-contrast);
}
.row-striped:nth-child(2n+1) ion-icon {
  color: var(--ion-color-secondary-contrast);
}
.row-striped:nth-child(2n+1) ion-radio {
  --color: $even-color;
  --color-checked: $even-color;
}
ion-list {
  --background: transparent;
  background: transparent;
}
ion-list.list-md {
  background: transparent;
}
ion-list ion-list-header {
  --background: transparent;
}
ion-list ion-item {
  --background: transparent;
}
ion-list ion-item:nth-child(2n+1) {
  --background: transparent;
}
form .row-striped {
  padding: 0;
}
form ion-item {
  --border-width: 0;
  --inner-border-width: 0;
}
form ion-item {
  --background: transparent;
  background: transparent;
}
form ion-item:nth-child(2n+1) {
  --background: transparent;
  background: transparent;
}
form ion-input {
  --background: transparent;
  background: transparent;
  text-align: right;
}
form ion-input ::placeholder,
form ion-input ::-webkit-input-placeholder,
form ion-input ::-moz-placeholder,
form ion-input :-ms-input-placeholder,
form ion-input :-moz-placeholder {
  text-align: right;
  margin-right: 8px;
}
form ion-textarea {
  border: 1px solid var(--ion-color-border);
  border-radius: 12px;
  padding: 4px;
}
form ion-button[type=submit] {
  text-transform: uppercase;
  font-weight: 600;
  margin: auto;
  justify-content: center;
  --padding: 16px;
  border-radius: 12px;
}
form ion-button[type=submit] * {
  --padding: 16px;
}
form ion-select {
  width: 100%;
  justify-content: center;
  text-align: right;
}
form .copiable-text {
  -webkit-user-select: text;
  user-select: text;
}
@media only screen and (min-height: 600px) {
  .modal-wrapper.ion-overlay-wrapper {
    min-height: 600px;
  }
}
.app-content form {
  width: 100%;
  background: var(--ion-color-container-background);
  color: var(--ion-color-container-background-contrast);
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 16px;
  border-radius: 10px;
  border-left: 1px solid var(--ion-color-border);
  border-right: 1px solid var(--ion-color-border);
  border-bottom: 1px solid var(--ion-color-border);
  border-top: 1px solid var(--ion-color-border);
}
.modal-wrapper .app-content form {
  border: 0;
}
.app-list {
  padding-bottom: 25px;
  padding-top: 25px;
}
.app-details {
  padding-bottom: 8px;
  padding-top: 8px;
}
.app-details-title {
  display: flex;
  justify-content: center;
  position: relative;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
}
.app-details-title ion-buttons {
  position: absolute;
  right: 0;
}
.app-details-title button {
  width: 35px;
  height: 35px;
}
.app-pool-container {
  display: block;
  width: 100%;
  height: 100%;
}
.app-pool-container .segment-label {
  font-size: 1.4rem;
}
.app-pool-container .col-left,
.app-pool-container .col-right {
  display: inline;
  width: 100%;
  padding: 0;
  height: 80vh;
}
.app-pool-container .col-left {
  float: left;
}
@media only screen and (min-width: 960px) {
  .app-pool-container .col-left {
    border-right: 2px solid var(--ion-color-container-background);
  }
}
.app-pool-container .col-right {
  float: right;
}
.app-pool-container .app-list-container,
.app-pool-container .app-details-container {
  background-color: var(--ion-color-container-background);
  color: var(--ion-color-container-background-contrast);
  display: block;
  width: 90%;
  min-height: 60vh;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid var(--ion-color-border);
  border-radius: 15px;
}
.app-pool-container .app-list {
  padding-bottom: 25px;
  padding-top: 25px;
}
.app-pool-container .app-details-title {
  margin: 15px 0px 0px 0px;
  padding: 0px 0px 0px 5%;
  font-size: 1.1em;
  font-weight: bold;
}
.app-pool-container .not-availiable-details-label {
  color: var(--ion-color-step-450);
  --color: var(--ion-color-step-450);
  font-size: 1.1em;
  text-align: center;
  font-weight: 500;
  padding-left: 20%;
  padding-right: 20%;
  margin: 16px;
}
.app-pool-container .app-details {
  display: block;
  width: 100%;
  height: 100%;
}
.app-pool-container .app-details .content {
  margin-bottom: 0px;
  padding-bottom: 0px;
  background-color: transparent;
}
.app-pool-container .app-details .scroll-content {
  margin-bottom: 0px;
  padding-bottom: 0px;
}
@media only screen and (max-width: 800px) {
  .app-pool-body-container .app-list-container {
    overflow-x: hidden;
    overflow-y: auto;
  }
  .app-pool-body-container ion-item .active-item,
.app-pool-body-container ion-item .active {
    border-right: 6px solid var(--ion-color-primary-shade);
  }
}
.app-pool-body-container .active ion-item {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
}
.app-pool-body-container .active ion-item ion-icon {
  background-color: var(--ion-color-secondary);
  color: var(--ion-color-secondary-contrast);
}
@media only screen and (min-width: 800px) {
  .app-pool-body-container .active-item,
.app-pool-body-container .activeItem {
    border-right: 4px solid var(--ion-color-secondary) !important;
  }
}
ion-popover .popover-content {
  background-color: var(--ion-background);
  color: var(--ion-background-contrast-contrast);
}
ion-popover .popover-content ion-item {
  margin: 5px;
  background-color: var(--ion-color-container-background);
  color: var(--ion-color-container-contrast);
  cursor: pointer;
  border-radius: 15px;
  --inner-border-width: 0px;
  border: 1px solid var(--ion-color-border);
}
ion-popover .popover-content ion-label {
  white-space: pre-line !important;
}
ion-popover .popover-content ion-radio {
  --color-checked: var(--ion-color-secondary);
}
ion-popover .popover-content::-webkit-scrollbar {
  display: none;
}
.app-list-container .client-list {
  padding-top: 16px;
}
.app-list-container ion-list {
  padding: 0;
}
.app-list-container ion-list .first-item {
  border-radius: 10px 10px 0px 0px !important;
}
.app-details ion-card {
  margin: 0 !important;
  box-shadow: 0 0 0 !important;
  background-color: transparent;
}
.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  white-space: normal;
}
.alert-tappable.alert-radio {
  height: auto;
  contain: content;
}
.app-radio-list ion-radio {
  --color: var(--ion-color-primary-contrast);
  --color-checked: var(--ion-color-primary);
}
.admin-item {
  border-radius: 12px;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
  width: 98%;
  margin-bottom: 5px;
}
.admin-item ion-row {
  width: 100%;
}
.admin-item .client-button {
  background: var(--ion-color-secondary-shade);
  border-bottom-right-radius: 12px;
  border-top-right-radius: 12px;
}
.admin-item .client-button span {
  display: inline-flex;
  height: 100%;
  align-items: center;
  align-content: center;
}
.admin-item .client-text {
  color: white;
  background: var(--ion-color-secondary);
  height: 89px;
  border-radius: 12px;
}
.admin-item .client-text ion-note {
  color: white;
}
.admin-item .client-text div {
  height: 100%;
  display: grid;
  align-self: center;
}
.admin-item .admin-label-note {
  margin-left: 35px;
}
.row-disabled {
  background-color: var(--ion-color-disabled);
  color: var(--ion-color-disabled-contrast);
}
.row-disabled ion-col {
  background-color: var(--ion-color-step-350) !important;
}
ion-button {
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  white-space: normal;
}
app-main-menu {
  display: flex;
  flex-shrink: 0;
  border-color: var(--ion-color-border-contrast);
  background-color: var(--ion-color-primary);
}
@media only screen and (max-width: 960px) {
  ion-modal .modal-wrapper {
    border-radius: 0px !important;
  }
  ion-modal .sc-ion-modal-md {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    display: block !important;
    width: 100% !important;
    height: 100% !important;
  }
}
.filter-container {
  border-bottom: 1px solid var(--ion-color-border);
  border-style: none none solid none;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.client-item {
  width: 30%;
}
.client-item ion-select {
  text-align: start;
  padding-left: 0;
  padding-right: 0;
}
.app-list-container .essential-data-list {
  padding-top: 16px;
  margin: 12px;
  display: flexbox;
  background-color: white;
}
.app-list-container .essential-data-list app-pool-user-item {
  width: 100%;
}
.app-list-container .admin-item {
  margin-bottom: 10px;
}
.app-list-container .client-label {
  display: grid;
  margin: 0;
  align-items: center;
  font-size: 1.4rem;
}
.checkbox-inline {
  display: flex;
  margin-top: 12px;
}
.radio-form {
  margin-top: 12px;
  display: flex;
}
.sop-details-score-custom-points {
  align-self: center;
  margin-left: auto;
}
.sop-details-open-custom-points {
  align-self: center;
  margin-left: auto;
}
.sop-list-buttons-coma ion-button {
  width: 224px;
}
.sop-details-form-item {
  display: flex;
}
.sop-list-header-operator {
  --border-color: var(--ion-item-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
  --inner-padding-end: 16px;
  --inner-border-width: 0 0 1px 0;
}
.sop-list-header-operator h4 {
  margin-left: auto;
  margin-right: auto;
}
.ppi_addedInfo {
  text-align: center;
  color: #707070;
  font-weight: 1.2em;
}
ion-row.ppi_patientsData {
  min-height: 50px;
}
ion-grid.ppi_main_pool_patiend_grid {
  border-radius: 12px;
  padding: 0;
  overflow: hidden;
}
.ppi_shadowHelperDiv {
  margin: 4px;
  box-shadow: 0 2px 4px 0 #d0d2d3;
  border-radius: 12px;
}
.pendingRequestedRow {
  background-color: #ceced2;
  height: 44px;
}
.pendingRequestedRow ion-col {
  text-align: center;
}
.cms-buttons {
  padding-bottom: 16px;
}
.cms-buttons ion-button {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.essential-style {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
  width: 100%;
}
.dragging {
  z-index: 111 !important;
}
.block {
  position: absolute;
  z-index: 9;
}
.indicator {
  width: 12px;
  height: 12px;
  border-radius: 60px;
  background-color: var(--ion-color-secondary);
  color: var(--ion-color-secondary-contrast);
  margin-top: -5px;
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.05, 0.03, 0.35, 1);
  transform: scale(1);
  position: absolute;
  z-index: 2;
}
.invisible {
  opacity: 0 !important;
  transform: scale(0);
}
.indicator:after {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  background-color: var(--ion-color-secondary);
  color: var(--ion-color-secondary-contrast);
  transform: scale(1.7);
  opacity: 0.2;
  border-radius: 60px;
}
.arrowblock {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  overflow: visible;
  pointer-events: none;
}
.arrowblock svg {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  overflow: visible;
}
.blockelem.flowchart-treatment-case .blockin {
  background-color: var(--ion-color-disabled);
  color: var(--ion-color-disabled-contrast);
}
.blockelem.flowchart-treatment-case.completed .blockin {
  background-color: var(--ion-color-secondary);
  color: var(--ion-color-secondary-contrast);
}
.blockelem.flowchart-treatment-case.inprogress .blockin {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
}
.blocktext {
  display: inline-block;
  vertical-align: top;
  margin-left: 12px;
}
.blocktitle {
  margin: 0px !important;
  padding: 0px !important;
  font-weight: 500;
  font-size: 16px;
  padding-top: 8px !important;
  text-align: center;
}
.blockin {
  width: 100%;
  height: 100%;
  background-color: var(--ion-color-secondary);
  color: var(--ion-color-secondary-contrast);
  display: inline-block;
  vertical-align: center;
  border-radius: 12px;
}
.blockdesc {
  margin-top: 5px;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
}
.selectedblock {
  border: 5px solid var(--ion-color-secondary-shade);
  box-shadow: 0px 4px 30px rgba(22, 33, 74, 0.08);
  border-radius: 18px;
  font-weight: bolder;
}
.selectedblock .blockin {
  background-color: var(--ion-color-secondary-tint);
  color: var(--ion-color-secondary-contrast);
}
.patient-details-grid {
  margin: 0px;
  border-radius: 15px;
}
.empty-app-details-label {
  color: var(--ion-color-step-450);
  --color: var(--ion-color-step-450);
  font-size: 1.1em;
  text-align: center;
  font-weight: 100;
  padding-left: 20%;
  padding-right: 20%;
  margin: 16px;
}
.patient-details-edit-button::part(native) {
  padding: 0px;
  padding-bottom: 28px;
}
ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}
.dragging{z-index:111!important}.block{position:absolute;z-index:9}.indicator{width:12px;height:12px;border-radius:60px;background-color:#217ce8;margin-top:-5px;opacity:1;transition:all .3s cubic-bezier(.05,.03,.35,1);transform:scale(1);position:absolute;z-index:2}.invisible{opacity:0!important;transform:scale(0)}.indicator:after{content:"";display:block;width:12px;height:12px;background-color:#217ce8;transform:scale(1.7);opacity:.2;border-radius:60px}.arrowblock{position:absolute;width:100%;overflow:visible;pointer-events:none}.arrowblock svg{width: -webkit-fill-available;overflow: visible;}

