// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #1b4044;
  --ion-color-primary-rgb: 27, 64, 68;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #18383c;
  --ion-color-primary-tint: #325357;

  --ion-color-secondary: #4cb8c4;
  --ion-color-secondary-rgb: 76, 184, 196;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #43a2ac;
  --ion-color-secondary-tint: #5ebfca;

  --ion-color-tertiary: #3dc2ff;
  --ion-color-tertiary-rgb: 61, 194, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #36abe0;
  --ion-color-tertiary-tint: #50c8ff;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #efeff4;
  --ion-color-medium-rgb: 239, 239, 244;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #d2d2d7;
  --ion-color-medium-tint: #f1f1f5;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-sop-title-color: #000000;
}

:root {
  --ion-font-family: "Open Sans", Helvetica, sans-serif;
  --ion-background-color: #efeff4;
  --ion-toolbar-background: var(--ion-color-primary);
  --ion-toolbar-color: var(--ion-color-primary-contrast);
  --ion-toolbar-height: 56px;

  --ion-color-border: #878787;
  --ion-color-border-rgb: 135, 135, 135;
  --ion-color-border-contrast: #000000;
  --ion-color-border-contrast-rgb: 0, 0, 0;
  --ion-color-border-shade: #777777;
  --ion-color-border-tint: #939393;

  --ion-color-disabled: #878787;
  --ion-color-disabled-rgb: 135, 135, 135;
  --ion-color-disabled-contrast: #000000;
  --ion-color-disabled-contrast-rgb: 0, 0, 0;
  --ion-color-disabled-shade: #777777;
  --ion-color-disabled-tint: #939393;

  --ion-color-container-background: #ffffff;
  --ion-color-container-background-rgb: 255, 255, 255;
  --ion-color-container-background-contrast: #000000;
  --ion-color-container-background-contrast-rgb: 0, 0, 0;
  --ion-color-container-background-shade: #e0e0e0;
  --ion-color-container-background-tint: #ffffff;
}
// not needed, yet
/*
.ion-color-border {
  --ion-color-base: var(--ion-color-border);
  --ion-color-base-rgb: var(--ion-color-border-rgb);
  --ion-color-contrast: var(--ion-color-border-contrast);
  --ion-color-contrast-rgb: var(--ion-color-border-contrast-rgb);
  --ion-color-shade: var(--ion-color-border-shade);
  --ion-color-tint: var(--ion-color-border-tint);
}

.ion-color-container-background {
  --ion-color-base: var(--ion-color-container-background);
  --ion-color-base-rgb: var(--ion-color-container-background-rgb);
  --ion-color-contrast: var(--ion-color-container-background-contrast);
  --ion-color-contrast-rgb: var(--ion-color-container-background-contrast-rgb);
  --ion-color-shade: var(--ion-color-container-background-shade);
  --ion-color-tint: var(--ion-color-container-background-tint);
}
*/
