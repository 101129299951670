/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "https://cdn.jsdelivr.net/gh/alyssaxuu/flowy/flowy.min.css";

// http://ionicframework.com/docs/theming/
// App Global Sass
// --------------------------------------------------
// Put style rules here that you want to apply globally. These
// styles are for the entire app and not just one component.
// Additionally, this file can be also used as an entry point
// to import other Sass files to be included in the output CSS.
//
// Shared Sass variables, which can be used to adjust Ionic's
// default Sass variables, belong in "theme/variables.scss".
//
// To declare rules for a specific mode, create a child rule
// for the .md, .ios, or .wp mode classes. The mode class is
// automatically applied to the <body> element in the app.
ion-modal {
  $background: var(--ion-color-container-background);
  $color: var(--ion-color-container-background-contrast);

  --background: $background;
  --border-color: var(--ion-color-border);

  .modal-wrapper {
    background-color: $background !important;
    color: $color !important;
    border-radius: 12px !important;

    ion-content {
      --background: $background;
      --color: $color;
      padding: 16px;
    }
  }
}

ion-backdrop {
  --backdrop-opacity: 0.66;
}

.split-pane-visible>.split-pane-side {
  min-width: 150px;
  /* Default: 270px */
  max-width: 200px;
  /* Same as when menu opens in mobile mode */
}

ion-toolbar {
  height: var(--ion-toolbar-height);
}

@mixin row-striped {
  $odd-background-color: var(--ion-color-container-background);
  $odd-color: var(--ion-color-container-background-contrast);
  $even-background-color: var(--ion-color-secondary);
  $even-color: var(--ion-color-secondary-contrast);

  --background: $odd-background-color;
  background: $odd-background-color;
  --color: $odd-color;
  color: $odd-color;

  ion-select::part(text),
  ion-select::part(icon) {
    color: $odd-color;
    opacity: 1;
  }

  ion-select::part(placeholder) {
    color: $odd-color;
    opacity: 0.8;
  }

  ion-select::part(text),
  ion-select::part(placeholder) {
    margin-right: 18px;
  }

  ion-item {
    --background: transparent;
    background: transparent;
  }

  ion-select {
    --background: transparent;
    background: transparent;
  }

  ion-item {
    color: $odd-color;
  }

  ion-label {
    color: $odd-color;
  }

  ion-text {
    color: $odd-color;
  }

  ion-icon {
    color: $odd-color;
  }

  ion-radio {
    --color: $odd-color;
    --color-checked: $odd-color;
  }

  &:nth-child(2n + 1) {
    --background: $even-background-color;
    background: $even-background-color;
    --color: $even-color;
    color: $even-color;

    ion-select::part(text),
    ion-select::part(icon) {
      color: $even-color;
    }

    ion-select::part(placeholder) {
      color: $even-color;
    }

    ion-item {
      --background: transparent;
      background: transparent;
    }

    ion-select {
      --background: transparent;
      background: transparent;
    }

    ion-item {
      color: $even-color;
    }

    ion-label {
      color: $even-color;
    }

    ion-text {
      color: $even-color;
    }

    ion-icon {
      color: $even-color;
    }

    ion-radio {
      --color: $even-color;
      --color-checked: $even-color;
    }
  }
}

.row-striped {
  @include row-striped();

  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
}

ion-list {
  --background: transparent;
  background: transparent;

  &.list-md {
    background: transparent;
  }

  ion-list-header {
    --background: transparent;
  }

  ion-item {
    --background: transparent;

    &:nth-child(2n + 1) {
      --background: transparent;
    }
  }
}

form {
  .row-striped {
    padding: 0;
  }

  ion-item {
    --border-width: 0;
    --inner-border-width: 0;
  }

  ion-item {
    --background: transparent;
    background: transparent;

    &:nth-child(2n + 1) {
      --background: transparent;
      background: transparent;
    }
  }

  ion-input {
    --background: transparent;
    background: transparent;

    text-align: right;

    ::placeholder,
    ::-webkit-input-placeholder,
    ::-moz-placeholder,
    :-ms-input-placeholder,
    :-moz-placeholder {
      text-align: right;
      margin-right: 8px;
    }
  }

  ion-textarea {
    border: 1px solid var(--ion-color-border);
    border-radius: 12px;
    padding: 4px;
  }

  ion-button[type="submit"] {
    text-transform: uppercase;
    font-weight: 600;
    margin: auto;
    justify-content: center;
    --padding: 16px;
    border-radius: 12px;

    * {
      --padding: 16px;
    }
  }

  ion-select {
    width: 100%;
    justify-content: center;
    text-align: right;
  }

  .copiable-text {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }
}

@media only screen and (min-height: 600px) {
  .modal-wrapper.ion-overlay-wrapper {
    min-height: 600px;
  }
}

.app-content {
  form {
    width: 100%;
    background: var(--ion-color-container-background);
    color: var(--ion-color-container-background-contrast);

    margin-left: auto;
    margin-right: auto;
    padding-bottom: 16px;

    border-radius: 10px;

    border-left: 1px solid var(--ion-color-border);
    border-right: 1px solid var(--ion-color-border);
    border-bottom: 1px solid var(--ion-color-border);
    border-top: 1px solid var(--ion-color-border);
  }
}

.modal-wrapper {
  .app-content {
    form {
      border: 0;
    }
  }
}

.app-list {
  padding-bottom: 25px;
  padding-top: 25px;
}

.app-details {
  padding-bottom: 8px;
  padding-top: 8px;
}

.app-details-title {
  display: flex;
  justify-content: center;
  position: relative;
  width: 90%;
  margin-right: auto;
  margin-left: auto;

  ion-buttons {
    position: absolute;
    right: 0;
  }

  button {
    width: 35px;
    height: 35px;
  }
}

.app-pool-container {
  display: block;
  width: 100%;
  height: 100%;

  .segment-label {
    font-size: 1.4rem;
  }

  .col-left,
  .col-right {
    display: inline;
    width: 100%;
    padding: 0;
    height: 80vh;
  }

  .col-left {
    float: left;

    @media only screen and (min-width: 960px) {
      border-right: 2px solid var(--ion-color-container-background);
    }
  }

  .col-right {
    float: right;
  }

  .app-list-container,
  .app-details-container {
    $background: var(--ion-color-container-background);
    $color: var(--ion-color-container-background-contrast);
    background-color: $background;
    color: $color;

    display: block;
    width: 90%;
    min-height: 60vh;
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid var(--ion-color-border);
    border-radius: 15px;
  }

  .app-list {
    padding-bottom: 25px;
    padding-top: 25px;
  }

  .app-details-title {
    margin: 15px 0px 0px 0px;
    padding: 0px 0px 0px 5%;
    font-size: 1.1em;
    font-weight: bold;
  }

  .not-availiable-details-label {
    color: var(--ion-color-step-450);
    --color: var(--ion-color-step-450);

    font-size: 1.1em;
    text-align: center;
    font-weight: 500;
    padding-left: 20%;
    padding-right: 20%;
    margin: 16px;
  }

  .app-details {
    display: block;
    width: 100%;
    height: 100%;

    .content {
      margin-bottom: 0px;
      padding-bottom: 0px;
      background-color: transparent;
    }

    .scroll-content {
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
  }
}

@media only screen and (max-width: 800px) {
  .app-pool-body-container {
    .app-list-container {
      overflow-x: hidden;
      overflow-y: auto;
    }

    ion-item {

      .active-item,
      .active {
        border-right: 6px solid var(--ion-color-primary-shade);
      }
    }
  }
}

.app-pool-body-container {
  .active ion-item {
    background-color: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);

    ion-icon {
      background-color: var(--ion-color-secondary);
      color: var(--ion-color-secondary-contrast);
    }
  }
}

@media only screen and (min-width: 800px) {
  .app-pool-body-container {

    .active-item,
    .activeItem {
      border-right: 4px solid var(--ion-color-secondary) !important;
    }
  }
}

ion-popover .popover-content {
  background-color: var(--ion-background);
  color: var(--ion-background-contrast-contrast);

  ion-item {
    margin: 5px;
    background-color: var(--ion-color-container-background);
    color: var(--ion-color-container-contrast);

    cursor: pointer;
    border-radius: 15px;
    --inner-border-width: 0px;
    border: 1px solid var(--ion-color-border);
  }

  ion-label {
    white-space: pre-line !important;
  }

  ion-radio {
    --color-checked: var(--ion-color-secondary);
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.app-list-container {
  .client-list {
    padding-top: 16px;
  }

  ion-list {
    padding: 0;

    .first-item {
      border-radius: 10px 10px 0px 0px !important;
    }
  }
}

.app-details {
  ion-card {
    margin: 0 !important;
    box-shadow: 0 0 0 !important;
    background-color: transparent;
  }
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  white-space: normal;
}

.alert-tappable.alert-radio {
  height: auto;
  contain: content;
}

.app-radio-list {
  ion-radio {
    --color: var(--ion-color-primary-contrast);
    --color-checked: var(--ion-color-primary);
  }
}

.admin-item {
  border-radius: 12px;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
  width: 98%;
  margin-bottom: 5px;

  ion-row {
    width: 100%;
  }

  .client-button {
    background: var(--ion-color-secondary-shade);
    border-bottom-right-radius: 12px;
    border-top-right-radius: 12px;

    span {
      display: inline-flex;
      height: 100%;
      align-items: center;
      align-content: center;
    }
  }

  .client-text {
    color: white;
    background: var(--ion-color-secondary);
    height: 89px;
    border-radius: 12px;

    ion-note {
      color: white;
    }

    div {
      height: 100%;
      display: grid;
      align-self: center;
    }
  }

  .admin-label-note {
    margin-left: 35px;
  }
}

.row-disabled {
  background-color: var(--ion-color-disabled);
  color: var(--ion-color-disabled-contrast);

  ion-col {
    background-color: var(--ion-color-step-350) !important;
  }
}

ion-button {
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  white-space: normal;
}

app-main-menu {
  display: flex;
  flex-shrink: 0;
  border-color: var(--ion-color-border-contrast);
  background-color: var(--ion-color-primary);
}

@media only screen and (max-width: 960px) {
  ion-modal {
    .modal-wrapper {
      border-radius: 0px !important;
    }

    .sc-ion-modal-md {
      position: absolute !important;
      top: 0 !important;
      left: 0 !important;
      display: block !important;
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.filter-container {
  border-bottom: 1px solid var(--ion-color-border);
  border-style: none none solid none;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.client-item {
  width: 30%;

  ion-select {
    text-align: start;
    padding-left: 0;
    padding-right: 0;
  }
}

.app-list-container {
  .essential-data-list {
    padding-top: 16px;

    margin: 12px;
    display: flexbox;
    background-color: white;

    app-pool-user-item {
      width: 100%;
    }
  }

  .admin-item {
    margin-bottom: 10px;
  }

  .client-label {
    display: grid;
    margin: 0;
    align-items: center;
    font-size: 1.4rem;
  }
}

  .checkbox-inline {
    display: flex;
    margin-top: 12px;
  }

.radio-form {
  margin-top: 12px;
  display: flex;
}

.sop-details-score-custom-points {
  align-self: center;
  margin-left: auto;
}
.sop-details-open-custom-points {
  align-self: center;
  margin-left: auto;
}

.sop-list-buttons-coma ion-button {
  width: 224px;
}

.sop-details-form-item {
  display: flex;
}

.sop-list-header-operator {
  --border-color: var(--ion-item-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
  --inner-padding-end: 16px;
  --inner-border-width: 0 0 1px 0;

  h4 {
    margin-left: auto;
    margin-right: auto;
  }
}

.ppi_addedInfo {
  text-align: center;
  color: #707070;
  font-weight: 1.2em;
}

ion-row.ppi_patientsData {
  min-height: 50px;
}

ion-grid.ppi_main_pool_patiend_grid {
  border-radius: 12px;
  padding: 0;
  overflow: hidden;
}

.ppi_shadowHelperDiv {
  margin: 4px;
  box-shadow: 0 2px 4px 0 #d0d2d3;
  border-radius: 12px;
}

.pendingRequestedRow {
  background-color: #ceced2;
  height: 44px;

  ion-col {
    text-align: center;
  }
}

.cms-buttons {
  ion-button {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  padding-bottom: 16px;
}

.essential-style {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
  width: 100%;
}

.dragging {
  z-index: 111 !important;
}

.block {
  position: absolute;
  z-index: 9;
}

.indicator {
  width: 12px;
  height: 12px;
  border-radius: 60px;
  background-color: var(--ion-color-secondary);
  color: var(--ion-color-secondary-contrast);
  margin-top: -5px;
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.05, 0.03, 0.35, 1);
  transform: scale(1);
  position: absolute;
  z-index: 2;
}

.invisible {
  opacity: 0 !important;
  transform: scale(0);
}

.indicator:after {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  background-color: var(--ion-color-secondary);
  color: var(--ion-color-secondary-contrast);
  transform: scale(1.7);
  opacity: 0.2;
  border-radius: 60px;
}

.arrowblock {
  // position: absolute;
  width: fit-content !important;
  overflow: visible;
  pointer-events: none;
}

.arrowblock svg {
  width: fit-content !important;
  overflow: visible;
}

.blockelem {
  &.flowchart-treatment-case {
    .blockin {
      background-color: var(--ion-color-disabled);
      color: var(--ion-color-disabled-contrast);
    }

    &.completed {
      .blockin {
        background-color: var(--ion-color-secondary);
        color: var(--ion-color-secondary-contrast);
      }
    }

    &.inprogress {
      .blockin {
        background-color: var(--ion-color-primary);
        color: var(--ion-color-primary-contrast);
      }
    }
  }
}

.blocktext {
  display: inline-block;
  vertical-align: top;
  margin-left: 12px;
}

.blocktitle {
  margin: 0px !important;
  padding: 0px !important;
  font-weight: 500;
  font-size: 16px;
  padding-top: 8px !important;
  text-align: center;
}

.blockin {
  width: 100%;
  height: 100%;
  background-color: var(--ion-color-secondary);
  color: var(--ion-color-secondary-contrast);
  display: inline-block;
  vertical-align: center;
  border-radius: 12px;
}

.blockdesc {
  margin-top: 5px;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
}

.selectedblock {
  .blockin {
    background-color: var(--ion-color-secondary-tint);
    color: var(--ion-color-secondary-contrast);
  }

  border: 5px solid var(--ion-color-secondary-shade);
  box-shadow: 0px 4px 30px rgba(22, 33, 74, 0.08);
  border-radius: 18px;
  font-weight: bolder;
}

.patient-details-grid {
  margin: 0px;
  border-radius: 15px;
}

.empty-app-details-label {
  color: var(--ion-color-step-450);
  --color: var(--ion-color-step-450);

  font-size: 1.1em;
  text-align: center;
  font-weight: 100;
  padding-left: 20%;
  padding-right: 20%;
  margin: 16px;
}

.patient-details-edit-button::part(native) {
  padding: 0px;
  padding-bottom: 28px;
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}